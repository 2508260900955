import type {
  User,
  UserSubscriptionDto,
  UserBranch,
  UserTitle,
  EducationDto,
  relUserDecisionChainDto,
  ArticleUserDto,
  NewsletterDto,
  OrderDto,
} from '~/typesAuto/apicore/v1'
import { CountryDto } from '~/typesAuto/apicore/v2'

// Partial apiCore User. Remove when proper partial field utility is implemented
export type MinimalUser = {
  id?: number
  client?: { id: number; jobPlace: string; zipcode: string }
  name?: string | null
  workEmail: string
  countryId?: number | null
  readingList?: ArticleUserDto[]
  zipcode?: string | null
  birthyear?: number | null
  educationId?: number | null
  workEmailConfirmed?: number
  avatar?: string | null
  contactEmail?: string | null
  contactPhone?: string | null
  userTitleId?: number | null
  userBranchId?: number | null
  autoLogin: string
}

export default () => {
  const { apiCoreFetch, identityApiFetch } = useApiFetch()

  const config = useRuntimeConfig()

  return {
    async getUser(id: number) {
      return await apiCoreFetch<MinimalUser>(`/v2/users/${id}`)
    },
    getPositions() {
      return apiCoreFetch<UserTitle[]>('/usertitles')
    },
    getUserPosition(id: number) {
      return apiCoreFetch<UserTitle>(`/usertitles/${id}`)
    },
    getEducation() {
      return apiCoreFetch<EducationDto[]>('/educations')
    },
    getIndustries() {
      return apiCoreFetch<UserBranch[]>('/userbranches')
    },
    getUserIndustry(id: number) {
      return apiCoreFetch<UserBranch>(`/userbranches/${id}`)
    },
    getCountries() {
      return apiCoreFetch<CountryDto[]>('/v2/countries')
    },
    async updateUser(id: number, patches: unknown[]) {
      try {
        await apiCoreFetch<User>(`/users/${id}`, {
          method: 'PATCH',
          body: patches,
          headers: { 'content-type': 'application/json-patch+json' },
        })

        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse('could not save user data changes', error)
      }
    },
    /**
     * @deprecated
     */
    async sendPasswordResetEmail(email: string) {
      const nuxtApp = useNuxtApp()
      try {
        await apiCoreFetch(`/users/forgotPassword`, {
          params: {
            email,
            latestUrl: `https://${config.public.site.url}/${nuxtApp.$t(
              'Routes.ForgotPassword'
            )}`,
          },
        })

        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse(
          'could not send change password email',
          error
        )
      }
    },
    async sendPasswordResetEmailJwt(email: string) {
      const nuxtApp = useNuxtApp()
      try {
        const requestUrl = useRequestURL()
        const returnUrl = `${requestUrl.origin}/${nuxtApp.$t(
          'Routes.ForgotPassword'
        )}`
        await identityApiFetch(`/v1/users/sendchangepassword`, {
          method: 'POST',
          body: {
            callingDomain: config.public.site.domainfull,
            email,
            returnUrl,
          },
        })
        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse(
          'could not send change password email jwt',
          error
        )
      }
    },

    async changePassword(passwordChangeToken: string, newPassword: string) {
      try {
        await apiCoreFetch('/users/changePassword', {
          method: 'POST',
          body: {
            confirmationToken: passwordChangeToken,
            newPassword,
          },
        })

        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse('could not change password', error)
      }
    },
    async getDecisionChains(userId: number) {
      return apiCoreFetch<relUserDecisionChainDto[]>(
        `/users/${userId}/decisionchains`
      )
    },
    async followDecisionChain(userId: number, decisionChainId: number) {
      return apiCoreFetch<relUserDecisionChainDto[]>(
        `/users/${userId}/decisionchains/${decisionChainId}`,
        {
          method: 'POST',
        }
      )
    },
    async unfollowDecisionChain(userId: number, decisionChainId: number) {
      return apiCoreFetch<relUserDecisionChainDto[]>(
        `/users/${userId}/decisionchains/${decisionChainId}`,
        {
          method: 'DELETE',
        }
      )
    },
    async getSubscribedNewsletters(userId: number) {
      return await apiCoreFetch<UserSubscriptionDto[]>(
        `users/${userId}/subscriptions`
      )
    },
    async subscribePaidNewsletter(email: string, paperId: string) {
      await apiCoreFetch(`/newsletters/paid/papers/${paperId}/subscribe`, {
        method: 'POST',
        body: {
          email: email,
        },
      })
    },
    async unsubscribePaidNewsletter(email: string, paperId: string) {
      await apiCoreFetch(`/newsletters/paid/papers/${paperId}/unsubscribe`, {
        method: 'POST',
        body: {
          email: email,
        },
      })
    },
    async getFreeNewsletters(userId: number) {
      return await apiCoreFetch<NewsletterDto[]>(
        `users/${userId}/newsletters/free`
      )
    },
    async subscribeFreeNewsletter(email: string) {
      try {
        await apiCoreFetch(`/newsletters/free/1/subscribe`, {
          method: 'POST',
          body: {
            email: email,
            ref: 'user profile',
          },
        })
        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse(
          'Subscription failed. Unable to process account/subscribeFreeNewsletter request',
          error
        )
      }
    },
    async unsubscribeFreeNewsletter(email: string) {
      await apiCoreFetch(`/newsletters/free/1/unsubscribe`, {
        method: 'DELETE',
        body: {
          email: email,
        },
      })
    },

    async getMarketingPermission(email: string) {
      try {
        await apiCoreFetch(
          `/Permissions/status?email=${encodeURIComponent(email)}`
        )
        const response = {
          status: 'success',
          code: 200,
        }
        return response
      } catch (error) {
        const typedError = error as { response?: { status?: number } }
        const response = {
          status: 'error',
          code: typedError.response?.status,
          error: typedError,
        }

        return response
      }
    },
    async unsubscribeMarketingPermission(email: string) {
      return await apiCoreFetch(`/Permissions/remove`, {
        method: 'PATCH',
        body: {
          email: email,
        },
      })
    },

    async getIterasPreAuth(userGuid: string) {
      return await apiCoreFetch<{ iterasPreauthToken: string }>(
        `/v1/users/${userGuid}/iteras/preauth`
      )
    },

    async getSubscriptionOrder(userGuid: string, orderId: string) {
      return await apiCoreFetch<OrderDto>(
        `/v1/Subscriptions/iteras/users/${userGuid}/orders/${orderId}`
      )
    },
  }
}
