/**
 * Access consent state from Cookiebot
 * Returns one ref for each consent type instead of a single ref with all consent types to make destructuring easier
 */
export const useCookieConsent = () => {
  const necessary = useState('cookieConsentNecessary', () => shallowRef(true))
  const functional = useState('cookieConsentFunctional', () =>
    shallowRef(false)
  )
  const statistic = useState('cookieConsentStatistic', () => shallowRef(false))
  const marketing = useState('cookieConsentMarketing', () => shallowRef(false))

  return {
    necessary,
    functional,
    statistic,
    marketing,
  }
}
