import type { ArticleUserDto, Paper } from '~/typesAuto/apicore/v1'
import type { UserWithClientDto } from '~/typesAuto/apicore/v2'
import type { SSOProvider } from '~/typesManual/sso'

// Remove when endpoints gets fixed dont care about other values
export type OldUser = {
  name?: string
  userId: number
  autologin: string
  loginType: 'IP' | 'newuser' | 'SSO' | 'WAYF'
  readingList: number[]
  paperList: number[]
  isAdmin: boolean
  title: string
  jobPlace?: string
  hasActiveSubscription: boolean
  hasActiveNonTrialSubscription: boolean
  workEmailConfirmed: number
  papers: number[]
  clientId: number
  error: string
}

export default () => {
  const { apiFetch, apiCoreFetch, identityApiFetch } = useApiFetch()

  return {
    // NOT TESTED part of NO site LOVEBAKKEN_PAPER_ID ugly hack in notification
    subscribeNewsletter(
      email: string,
      newsletter: string | number,
      consent: boolean
    ) {
      return apiFetch<OldUser>('Subscribe', {
        method: 'POST',
        query: { email, newsletter, consent },
      })
    },
    async subscribeFreeNewsletter(
      email: string,
      newsletterId: string | number
    ) {
      try {
        await apiCoreFetch(`newsletters/free/${newsletterId}/subscribe`, {
          method: 'POST',
          body: { email, ref: 'web' },
        })

        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse(
          'Subscription failed. Unable to process subscribeFreeNewsletter request',
          error
        )
      }
    },
    async addMarketingPermission(permission: {
      email: string
      firstName?: string
      lastName?: string | null
      company?: string
      jobTitle?: string
      phone?: string
      permissionSource: string
      permissionSourceUrl: string
    }) {
      try {
        await apiCoreFetch(`/Permissions/add`, {
          method: 'POST',
          body: permission,
        })
        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse(
          'Permission failed. Unabel to process addMarketingPermission request',
          error
        )
      }
    },
    async autologin(autologin: string) {
      return await apiFetch<OldUser>('Login', { query: { autologin } })
    },
    async iplogin() {
      return await apiFetch<OldUser>('IPLogin')
    },
    async trialSignup(form: Record<string, unknown>) {
      return await apiFetch<OldUser>('TrialSignup', {
        method: 'POST',
        body: form,
      })
    },
    async saveToReadingList(
      userId: number,
      articleId: number,
      paperId: number
    ) {
      return apiCoreFetch(`users/${userId}/articles/${articleId}`, {
        method: 'POST',
        body: { Id: paperId },
      })
    },
    async removeFromReadingList(userId: number, articleId: number) {
      return apiCoreFetch(`users/${userId}/articles/${articleId}`, {
        method: 'DELETE',
      })
    },
    async getReadingList(userId: number, articleRead?: boolean) {
      return apiCoreFetch<ArticleUserDto[]>(`users/${userId}/articles`, {
        query: { articleRead: articleRead },
      })
    },
    async getReadingListWithPagination({
      userId,
      articleRead,
      pageSize,
      skip,
    }: {
      userId: number
      articleRead?: boolean
      pageSize?: number
      skip?: number
    }): Promise<{ data: ArticleUserDto[]; totalPages: number }> {
      return apiCoreFetch<
        Promise<{ data: ArticleUserDto[]; totalPages: number }>
      >(`/v2/users/${userId}/articles`, {
        query: { articleRead, pageSize, skip },
      })
    },
    async papers(userId: number) {
      return await apiCoreFetch<Paper[]>(`users/${userId}/papers`)
    },
    async paper(paperId: number, userId: number) {
      return await apiCoreFetch<Paper>(`users/${userId}/papers/${paperId}`)
    },
    async createUser({
      workEmail,
      password,
    }: {
      workEmail: string
      password: string
    }) {
      try {
        const response = await apiCoreFetch<UserWithClientDto>('/v3/users', {
          method: 'POST',
          body: { workEmail, password },
        })

        return createSuccessDataResponse(response)
      } catch (error) {
        return createErrorDataResponse<UserWithClientDto>(
          'userCreate: could not create user',
          error
        )
      }
    },
    async createUserWithDetails(user: {
      contactPhone: string
      name: string
      password: string
      userTitleId: number
      workEmail: string
      birthyear?: string
      countryId?: number
      educationId?: number
      userBranchId?: number
      zipcode?: string
    }) {
      try {
        const response = await apiCoreFetch<UserWithClientDto>('/v3/users', {
          method: 'POST',
          body: user,
        })
        return createSuccessDataResponse(response)
      } catch (error) {
        return createErrorDataResponse<UserWithClientDto>(
          'userCreate: could not create user',
          error
        )
      }
    },
    async updateUser(
      userId: number,
      data: Record<string, string | number | boolean>
    ) {
      try {
        await apiCoreFetch(`users/${userId}`, {
          method: 'PATCH',
          body: Object.keys(data).map((path) => ({
            op: 'add',
            path,
            value: data[path],
          })),
        })

        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse('could not save user data changes', error)
      }
    },
    async sendConfirmationEmailJwt(
      callingDomain: string,
      email: string,
      returnUrl: string,
      paperIds?: string
    ) {
      const bodyData: { [key: string]: unknown } = {
        callingDomain,
        email,
        returnUrl,
      }
      if (paperIds) {
        bodyData['paperIds'] = paperIds.split(',').map((id) => parseInt(id))
      }

      try {
        await identityApiFetch(`/v1/users/sendconfirmEmail`, {
          method: 'POST',
          body: bodyData,
        })
        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse('errorOccurred', error)
      }
    },
    async confirmEmailJwt(userGuid: string, confirmEmailToken: string) {
      try {
        await identityApiFetch<void>(`/v1/users/${userGuid}/confirmemail`, {
          method: 'POST',
          body: { confirmEmailToken },
        })

        return createSuccessResponse()
      } catch (e) {
        return createErrorResponse('Could not confirm JWT email', e)
      }
    },

    /**
     * @deprecated
     */
    async sendConfirmationEmail(
      userId: number,
      returnUrl: string,
      emailType: 'ats' | 'standart',
      sendWorkEmail = true,
      sendPrivateEmail = false,
      paperIds?: string
    ) {
      const queryData: { [key: string]: unknown } = {
        returnUrl,
        sendWorkEmail,
        sendPrivateEmail,
      }
      if (paperIds) {
        queryData['paperIds'] = paperIds
      }

      try {
        if (emailType === 'standart') {
          await apiCoreFetch(`/v1/users/${userId}/confirmEmail`, {
            query: queryData,
          })
        } else {
          await apiCoreFetch(`/v2/users/${userId}/confirmEmail`, {
            query: queryData,
          })
        }
        return createSuccessResponse()
      } catch (error) {
        return createErrorResponse('errorOccurred', error)
      }
    },
    /**
     * @deprecated
     */
    async confirmEmail(emailConfirmToken: string) {
      try {
        const result = await apiCoreFetch<{ id: number; autoLogin: string }>(
          `users/confirmEmail`,
          {
            method: 'PATCH',
            body: [{ op: 'add', path: '/WorkEmailConfirmed', value: '1' }],
            query: { token: emailConfirmToken },
          }
        )

        return createSuccessDataResponse(result)
      } catch (e) {
        return createErrorDataResponse<{ id: number; autoLogin: string }>(
          'Could not confirm email',
          e
        )
      }
    },
    async sendAccessGrantedReceiptEmail(userId: number, paperIds: string) {
      try {
        await apiCoreFetch(
          `/v2/users/${userId}/sendAccessGrantedReceiptEmail/?paperids=${paperIds}`
        )
        return createSuccessResponse()
      } catch (e) {
        return createErrorResponse('Could not send access granted receipt', e)
      }
    },

    async getSsoProviderByEmail(email: string) {
      try {
        return await apiCoreFetch<SSOProvider>(`/SSO`, {
          query: { email },
        })
      } catch {
        return null
      }
    },
    async ssoLogin() {
      return apiCoreFetch<OldUser>('login')
    },
    async isUser(email: string): Promise<boolean> {
      try {
        const response = await identityApiFetch('/v1/Users/isuser', {
          query: { email },
        })
        return response !== null
      } catch {
        return false
      }
    },
    async isPasswordSet(email: string) {
      try {
        const response = await identityApiFetch('/v1/Users/ispasswordset', {
          query: { email },
        })
        return createSuccessDataResponse<boolean>(response !== null)
      } catch (error) {
        return createErrorDataResponse<boolean>(
          'Could not check if password is set',
          error
        )
      }
    },
  }
}
